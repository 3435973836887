import React from "react";
import Footer from "../components/Footer";
import Loader from "../UI/Loader";

const LoadingPage = () => {
  return (
    <>
      <section
        className="section d-flex justify-content-center min-100-vh
    min-100-vb align-items-center"
      >
        <Loader width={40} height={40} dark />
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default LoadingPage;
