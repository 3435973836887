import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="px-3 py-3  d-flex justify-content-center align-items-center text-center">
      {`© Copyright O2B  ${year} All Rights Reserved`}
    </footer>
  );
};

export default Footer;
